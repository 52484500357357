import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { FC } from "react"
import Header from "../components/header"
import Layout from "../components/layout"

export const query = graphql`
  query {
    mainImage: file(name: { eq: "byensgaardbutik" }) {
      childImageSharp {
        id
        fluid(maxWidth: 1152, maxHeight: 420, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const Afhentning: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge

  return (
    <Layout title="Afhentning af dagens ret" aar={aar} uge={uge}>
      <Img fluid={props.data.mainImage.childImageSharp.fluid} />
      <div className="text-center">
        <h1 className="px-8 text-xl text-center lg:text-3xl md:px-0">
          Afhentning af dagens ret
        </h1>
        Afhentning foregår mellem 15:00 og 17:00
        <br />
        hos Byens Gårdbutik i Svendborg
      </div>

      <div className="grid grid-cols-1 my-8 md:grid-cols-2">
        <div className="flex flex-col justify-center p-12 bg-main-smoke">
          <Header>Her afhenter du</Header>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/Byens+G%C3%A5rdbutik/@55.059531,10.603866,892m/data=!3m1!1e3!4m5!3m4!1s0x0:0xe9377dca972369a2!8m2!3d55.0595308!4d10.6038655?hl=da"
            className="uppercase strong"
          >
            Byens Gårdbutik
          </a>
          <p>Vestergade 23 - 25</p>
          <p>Svendborg</p>
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/Voldgade+Vest+parkeringsplads/@55.0590737,10.6027804,223m/data=!3m1!1e3!4m12!1m6!3m5!1s0x464d3310907c72b1:0xe9377dca972369a2!2sByens+G%C3%A5rdbutik!8m2!3d55.0595308!4d10.6038655!3m4!1s0x464d3313ebae4be7:0x85acaa1273d90eb8!8m2!3d55.0584879!4d10.603017"
          >
            Parkering i Voldgade
          </a>
        </div>
        <iframe
          title="Kort til Gårdbutikken"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.4554421376931!2d10.603488491666674!3d55.059379361576724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464d3310907c72b1%3A0xe9377dca972369a2!2sByens%20G%C3%A5rdbutik!5e1!3m2!1sda!2sdk!4v1591257572286!5m2!1sda!2sdk"
          width="100%"
          height="420"
          style={{ border: 0 }}
        />
      </div>
    </Layout>
  )
}

export default Afhentning
